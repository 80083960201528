import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { ApiEndPointUrl, MyHeaders, pageSize } from '@fuse/utils/systemEnums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterSettingsService  implements Resolve<any>{
  reports: any;
  plants: any;
  masterData: any;
  pageIndex: any;
  totalRecords: any;
  reportPageIndex: any;
  reportTotalRecords: any;
  tableData: any;
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getReports(),
        this.getPlants(),
        this.getMaster(),
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    });
  }
  constructor(public commonService: CommonService,
    private _httpClient: HttpClient) { }

  //------------Reports Apis----------------- 

  getMaster(){
    this.commonService.getMaster().then((result:any)=>{
      if(result.status == 200)
        this.masterData = result.data;
    })
  }

  
 
  getReports(pageNumber?: any, keyword?: any, date?: any,col?,orderBy?) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl+'admin/report-type/getReportTypes',{
        pageIndex:pageNumber,
        keyword:keyword? keyword:'',
        date:date?date:'',
        columnName:col?col :'',
        orderby: orderBy? orderBy:'' 
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            this.tableData = result.data
          }else{
            this.reports = []
          }
          resolve(result)
        }, reject)
    })
  }

  addReport(reportName) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/report-type',{'name':reportName}, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            this.reports = result.data
          }else{
            this.reports = []
          }
          resolve(result)
        }, reject)
    })
  }

  editReport(reportName,id) {
    return new Promise((resolve, reject) => {
      this._httpClient.put(ApiEndPointUrl + 'admin/report-type/'+id,{'name':reportName}, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            this.reports = result.data
            console.log("edit report",this.reports);
            
          }else{
            this.reports = []
          }
          resolve(result)
        }, reject)
    })
  }

  deleteReport(id) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/report-type/delete' ,{reportTypeId:id}, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            this.reports = result.data
          } else {
            this.reports = []
          }
          resolve(result)
        }, reject)
    })
  }

// ------------Plants Apis------------------------
// 
  getPlants(pageNumber?: any, keyword?: any, date?: any,col?,orderBy?) { 
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl+'admin/plant-type',{
        pageIndex:pageNumber,
        keyword:keyword? keyword:'',
        date:date?date:'',
        columnName:col?col :'',
        orderby: orderBy? orderBy:'' 
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            this.plants = result.data.plantTypes
            this.pageIndex =result.data.pageIndex
            this.totalRecords = result.data.totalRecords
            console.log("plant data",result.data.pageIndex)
          }else{
            this.reports = []
          }
          resolve(result)
        }, reject)
    })
  }

  addPlant(data) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/plant-type/add',data, MyHeaders.getMyHeaders().reqHeadersFormData)
        .subscribe((result: any) => {
          if (result.status) {
            this.reports = result.data
          }else{
            this.reports = []
          }
          resolve(result)
        }, reject)
    })
  }

  editPlant(data) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/plant-type/update',data, MyHeaders.getMyHeaders().reqHeadersFormData)
        .subscribe((result: any) => {
          if (result.status) {
            this.reports = result.data.plantTypes
            console.log("edit plant",this.reports);
            
          }else{
            this.reports = []
          }
          resolve(result)
        }, reject)
    })
  }

  deletePlant(id) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/plant-type/delete' ,{id:id}, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            this.reports = result.data.plantTypes
            console.log("delete plant",this.reports);
            
          } else {
            this.reports = []
          }
          resolve(result)
        }, reject)
    })
  }



  addPlantTypeExcel(data) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/plant-type/addPlantTypeExcel',data, MyHeaders.getMyHeaders().reqHeadersFormData)
        .subscribe((result: any) => {
          if (result.status)
          resolve(result);
          
        }, reject)
    })
  }

  // -----------------
  // --------Get Plants Waatering------------


  // Update in range
  userRange(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post('https://api.plantcare.in/api/v4/' + 'admin/updateEventNotificationRange',data, MyHeaders.getMyHeaders().reqHeadersFormData)
        .subscribe((result: any) => {
          if (result.status)
          resolve(result);
          
        }, reject)
    })
  }

  canCreateEvent(data: any){
    return new Promise((resolve, reject) => {
      this._httpClient.post('https://api.plantcare.in/api/v4/' + 'admin/updateEventAllowance',data, MyHeaders.getMyHeaders().reqHeadersFormData)
        .subscribe((result: any) => {
          if (result.status)
          resolve(result);
          
        }, reject)
    })
  }
  
  
}
